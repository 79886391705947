import { Card, Grid, Typography } from "@mui/material";
import {
  AccessibilityNew,
  CheckCircle,
  ForumOutlined,
  FullscreenExitOutlined,
  GroupAdd,
  Inventory2Outlined,
  LanguageOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { EventReducer, FormSections, ContactAccessPermissionDefaults, } from "./ContactAccessPermissionForm";
import ContactAccessPermissionTable, { GetContactAccessPermissionColumns } from "./ContactAccessPermissionTable";
import ContactAccessSubscriptionsTable, { GetContactAccessSubscriptionsColumns } from "./ContactAccessSubscriptionsTable";
import ContactReportsPermissionTable, { GetContactReportsPermissionColumns } from "./ContactReportsPermissionTable";
import useFetch from "./../../../../hooks/useFetch";
import Form from "./../../../../components/Form";
import { CONTACTS_APIS } from "./../../../../common/apis";
import FormFooter from "../../../../components/FormFooter";
import ProductSearch from "../../../quick-details/manage/search/ProductSearch";
import AppDialog from "../../../../components/AppDialog";
import SiteSetSearch from "../../../management/SiteSetSearch";
import ContactSampleReportingMapping from "./ContactSampleReportingMapping";
import ContactSubscriptionMapping from "./ContactSubscriptionMapping";
import SearchCustomer from "../../../quick-details/manage/search/SearchCustomer";
import SearchSite from "../../../quick-details/manage/search/SearchSite";
import SearchUnit from "../../../quick-details/manage/search/SearchUnit";
import SearchComponent from "../../../quick-details/manage/search/SearchComponent";
import SearchParent from "../../../quick-details/manage/search/SearchParent";
import ConfirmModal from "../../../../components/ConfirmModal";

export default function ContactAccessPermission({
  contactId,
  propId,
  username,
  searchContactRowData,
}) {
  const { get, post, postFile } = useFetch();

  const [accessData, setAccessData] = useState([]);
  const [linkagesData, setLinkagesData] = useState([]);
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [openSelectSite, setOpenSelectSite] = useState(false);
  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const [openSelectComponent, setOpenSelectComponent] = useState(false);
  const [reportRowSelected, setReportRowSelected] = useState();
  const [customerData, setCustomerData] = useState({})
  const [localData, setLocalData] = useState({});
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    message: "",
    width: "",
    actionName: "",
  });

  const [data, dispatch] = useReducer(EventReducer, ContactAccessPermissionDefaults);

  const actions = {
    addProduct: async function () {
      setDialog({
        title: "Add Product Disclaimer",
        open: true,
        message:
          "This will give access to the all samples within this PRODUCT (e.g KOWA, Labcheck) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation.\n This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.",
        width: "md",
        actionName: "addProductAlert",
      });
    },
    addProductAlert: async function () {
      setDialog({ open: true, title: "Product Search", width: "xl" });
    },
    addParent: async function () {
      setDialog({
        title: "Add Parent Disclaimer",
        open: true,
        message:
          "This will give access to the all samples within this PARENT (e.g Rio Tinto) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation. \n This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.",
        width: "md",
        actionName: "addParentAlert",
      });
    },
    addParentAlert: async function () {
      setDialog({ open: true, title: "Parent Search", width: "xl" });
    },
    addCustomer: async function () {
      setOpenSelectCustomer(true);
    },
    addSite: async function () {
      setOpenSelectSite(true);
    },
    addUnit: async function () {
      setOpenSelectUnit(true);
    },
    addComponent: async function () {
      setOpenSelectComponent(true);
    },

    addSiteSet: async function () {
      setDialog({
        title: "Add SiteSet Disclaimer",
        open: true,
        message: `This will give access to the all samples within this SITE SET (e.g Komatsu New Zealand) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation.\n  
         This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.`,
        width: "md",
        actionName: "addSiteSetAlert",
      });
    },
    addSiteSetAlert: async function () {
      setDialog({ open: true, title: "SiteSet Search", width: "xl" });
    },
    matchAccessAlert: async function () {
      setDialog({
        title: "Apply Access Permissions to Sample Report Mappings",
        open: true,
        message: `This will remove the sample report mappings that this contact receives, and replace them and will use the access permissions for the user instead. Only customer, site, unit and components will be copied, with the alert level you have specified in the contact sample reports window.
        \nThis will create Sample Reports from a Normal level, and SMSes from a Action level.
        \n.\nDo you wish to proceed?
        \n"Yes" = Replace all sample reports
        \n"No" = Do not replace
         `,
        width: "md",
        actionName: "matchAccess",
      });
    },
    matchAccess: async function () {
      const payload = {
        ContactId: contactId || localData?.ContactId,
      };
      const pResp = await post(CONTACTS_APIS.CONTACT_SCOPE_TO_LINKAGES, payload);
      setDialog({ open: false });
      if (pResp > 0) {
        return getData();
      }
      else {
        console.log("we'll add something here later");
      }
    },
    exportClick: async function () {
      let col = [];
      let title = "Export ";

      if (propId === 2 || localData?.PropId === 2) {
        title += "Contact Access Permissions - " + (localData?.UserName || "");
        col = GetContactAccessPermissionColumns();
      }
      else if (propId === 3 || localData?.PropId === 3) {
        title += "Contact Sample Reporting - " + (localData?.UserName || "");
        col = GetContactReportsPermissionColumns();
      }
      else if (propId === 4 || localData?.PropId === 4) {
        title += "Contact Subscription - " + (localData?.UserName || "");
        col = GetContactAccessSubscriptionsColumns();
      }

      const pCols = col.reduce((acc, key) => {
        if (key.header != "Delete" && key.header != "Edit")
          acc[key.accessorKey] = key.header; // Set both the key and value to be the same
        return acc;
      }, {});

      await postFile(`${CONTACTS_APIS.EXPORT_CAP_XLSX}`, {
        ContactId: contactId || localData?.ContactId,
        PropId: propId || localData?.PropId,
        columns1: pCols
      }, title);
    },
  };

  const filteredAccessData = useMemo(
    () =>
      data
        ? (accessData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.Scope?.toLowerCase().includes(filterLower);
          }
          return (
            (filterMatch && data.Product && x.Scope === "Product") ||
            (filterMatch && data.Customer && x.Scope === "Customer") ||
            (filterMatch && data.Parents && x.Scope === "Parent") ||
            (filterMatch && data.Sites && x.Scope === "Site") ||
            (filterMatch && data.Units && x.Scope === "Unit") ||
            (filterMatch && data.Components && x.Scope === "Component") ||
            (filterMatch && data.SiteSets && x.Scope === "Site Set")
          );
        })
        : accessData,
    [data, accessData]
  );

  const filteredData = useMemo(
    () =>
      data
        ? (linkagesData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.LevelText?.toLowerCase().includes(filterLower) ||
              x.Site?.toLowerCase().includes(filterLower);
          }
          return (
            filterMatch &&
            ((data.Product && x.LevelText === "Product") ||
              (data.Customer && x.LevelText === "Customer") ||
              (data.Parents && x.LevelText === "Parent") ||
              (data.Sites && x.LevelText === "Site") ||
              (data.Units && x.LevelText === "Unit") ||
              (data.SiteSets && x.LevelText === "Site Set") ||
              (data.Components && x.LevelText === "Component") ||
              (filterMatch && data.SiteSets && x.LevelText === "Site Set"))
          );
        })
        : linkagesData,
    [data, linkagesData]
  );
  const filteredSubscriptionData = useMemo(
    () =>
      data
        ? (linkagesData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.Scope?.toLowerCase().includes(filterLower);
          }
          return (
            (filterMatch && data.Product && x.LevelText === "Product") ||
            (filterMatch && data.Customer && x.LevelText === "Customer") ||
            (filterMatch && data.Parents && x.LevelText === "Parent") ||
            (filterMatch && data.Sites && x.LevelText === "Site") ||
            (filterMatch && data.Units && x.LevelText === "Unit") ||
            (filterMatch && data.Components && x.LevelText === "Component") ||
            (filterMatch && data.SiteSets && x.LevelText === "Site Set")
          );
        })
        : linkagesData,
    [data, linkagesData]
  );

  useEffect(() => {
    (async () => {
      if (contactId > 0) {
        getData();
      }
      else {
        let lData = JSON.parse(localStorage.getItem("contact-access-permission"));
        if (lData?.PropId == 3) {
          document.title = "Contact Sample Reporting - " + (lData?.UserName || "");
        }
        else if (lData?.PropId == 4) {
          document.title = "Contact Subscription - " + (lData?.UserName || "");
        }
        else {
          document.title = "Contact Access Permissions - " + (lData?.UserName || "");
        }
        if (lData?.ContactId > 0) {
          setLocalData(lData)
          getData(lData?.ContactId, lData?.PropId);

        }
      }
    })();
  }, []);

  async function getData(a, p) {
    if (propId === 2 || p === 2 || localData?.PropId === 2) {
      const contactScopeResponse = await get(`${CONTACTS_APIS.CONTACT_SCOPES_ASSIGNED}?contactId=${a || contactId || localData?.ContactId || 0}`, { skipBlocker: true });
      setAccessData(contactScopeResponse);
    } else if (propId === 3 || p === 3 || localData?.PropId === 3) {
      const linkagesResponse = await get(`${CONTACTS_APIS.CONTACT_LINKAGES_ASSIGNED}?contactId=${a || contactId || localData?.ContactId || 0}`, { skipBlocker: true });
      setLinkagesData(linkagesResponse);
    } else if (propId === 4 || p === 4 || localData?.PropId === 4) {
      const contactSubscriptionsResponse = await get(`${CONTACTS_APIS.CONTACT_SUBSCRIPTIONS}?contactId=${a || contactId || localData?.ContactId || 0}`, { skipBlocker: true });
      setLinkagesData(contactSubscriptionsResponse);
    }
  }

  function editReportMappingClicked(row) {
    setReportRowSelected(row);
    setDialog({
      open: true,
      title: `Contact Sample Report Mapping for ${username || localData?.UserName || ""}`,
      width: "md",
    });
  }

  //handle customer row
  async function searchProductSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        ProductId: row.ProductId,
        Screen: "Contact Access Permissions",
      };
      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setDialog({ open: false });
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      return setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
    }
    else {
      editReportMappingClicked(row)
    }
  }

  async function searchParentSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        ParentId: row.ParentId,
        Screen: "Contact Access Permissions",
      };

      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setDialog({ open: false });
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      return setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
    }

    else {
      editReportMappingClicked(row)
    }
  }

  async function searchSiteSetSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        SiteSetId: row.SiteSetId,
        Screen: "Contact Access Permissions",
      };

      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setDialog({ open: false });
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      return setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
    }

    else {
      editReportMappingClicked(row)
    }
  }

  async function searchCustomerSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        CustomerId: row.CustomerId,
        Screen: "Contact Access Permissions",
      };

      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setOpenSelectCustomer(false);
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
      await getData();
      return setOpenSelectCustomer(false);
    }

    else {
      editReportMappingClicked(row)
      return setOpenSelectCustomer(false);
    }
  }

  async function searchSiteSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        SiteId: row.SiteId,
        Screen: "Contact Access Permissions",
      }
      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setOpenSelectSite(false);
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
      await getData();
      return setOpenSelectSite(false);
    }

    else {
      editReportMappingClicked(row)
      return setOpenSelectSite(false);
    }
  }

  async function searUnitSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        UnitId: row.UnitId,
        Screen: "Contact Access Permissions",
      };
      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setOpenSelectUnit(false);
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
      await getData();
      return setOpenSelectUnit(false);
    }

    else {
      editReportMappingClicked(row)
      return setOpenSelectUnit(false);
    }
  }

  async function searComponentsSelect(row) {
    if (propId === 2 || localData?.PropId === 2) {
      const payload = {
        ContactId: contactId || localData?.ContactId,
        ComponentId: row.ComponentId,
        Screen: "Contact Access Permissions",
      };
      await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, payload);
      await getData();
      return setOpenSelectComponent(false);
    }
    else if (propId === 4 || localData?.PropId === 4) {
      setCustomerData(row)
      setDialog({
        open: true,
        title: `Contact Subscription Mapping for ${username || localData?.UserName || ""}`,
        width: "md",
      });
      await getData();
      return setOpenSelectComponent(false);
    }

    else {
      editReportMappingClicked(row)
      return setOpenSelectComponent(false);
    }
  }

  return (
    <>
      <Grid container spacing={1} sx={{ minWidth: "95vw", minHeight: "75vh" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form sections={FormSections()} data={data} dispatch={dispatch} />
            <FormFooter
              buttons={GetFormButtons(searchContactRowData, localData, actions,)}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography >
            {filteredAccessData.length > 0 && (
              <b>Total Records: {filteredAccessData.length}</b>
            )}

            {filteredData.length > 0 && (
              <b>Total Records: {filteredData.length}</b>
            )}

            {(propId === 4 || localData?.PropId === 4) && filteredSubscriptionData.length === 0 && (
              <p style={{ color: "red" }}>
                No report subscriptions have been added - You can only add
                subscriptions for customers, etc that has Access Permissions to
              </p>
            )}
            {(propId === 2 || localData?.PropId === 2) && filteredAccessData.length === 0 && (
              <p style={{ color: "red" }}>
                The contact has no access - add the required customers etc that
                they should be able to see
              </p>
            )}
            {(propId === 3 || localData?.PropId === 3) && filteredData.length === 0 && (
              <p style={{ color: "red" }}>
                No sample reporting has been added - You can only add reports
                for customers, etc that {searchContactRowData?.DisplayName || localData?.DisplayName} has
                Access Permissions to
              </p>
            )}
          </Typography>

          {(propId === 2 || localData?.PropId === 2) ? (
            <ContactAccessPermissionTable
              username={username || localData?.UserName}
              getData={getData}
              data={filteredAccessData}
            />
          ) : (propId === 3 || localData?.PropId === 3) ? (
            <ContactReportsPermissionTable
              username={username || localData?.UserName}
              getData={getData}
              data={filteredData}
              editReportMappingClicked={editReportMappingClicked}
            />
          ) : (propId === 4 || localData?.PropId === 4) ? (
            <ContactAccessSubscriptionsTable
              username={username || localData?.UserName}
              getData={getData}
              data={filteredSubscriptionData} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        {dialog.title === "Apply Access Permissions to Sample Report Mappings" && (
          <ConfirmModal
            handleClose={() => { setDialog((prevState) => ({ ...prevState, open: false })); }}
            alertAction={actions.matchAccess}
            message={dialog.message}
          />
        )}
        {dialog.title === "Product Search" && (
          <ProductSearch
            selectClick={searchProductSelect}
            ContactId={contactId || localData?.ContactId}
            setDialog={setDialog}
            getData={getData}
            propId={propId || localData?.PropId}
          />
        )}

        {dialog.title === "Parent Search" && (
          <SearchParent
            selectClick={searchParentSelect}
            ContactId={contactId || localData?.ContactId}
            setDialog={setDialog}
            getData={getData}
            propId={propId || localData?.PropId}
          />
        )}

        {dialog.title === "SiteSet Search" && (
          <SiteSetSearch
            selectClick={searchSiteSetSelect}
            ContactId={contactId || localData?.ContactId}
            setDialog={setDialog}
            getData={getData}
            propId={propId || localData?.PropId}
          />
        )}

        {dialog.title === `Contact Sample Report Mapping for ${username || localData?.UserName || ""}` && (
          <ContactSampleReportingMapping
            selectedRowData={reportRowSelected}
            contactId={contactId || localData?.ContactId}
            setDialog={setDialog}
            getData={getData}
          />
        )}

        {dialog.title === `Contact Subscription Mapping for ${username || localData?.UserName || ""}` && (
          <ContactSubscriptionMapping
            handleClose={() =>
              setDialog((prevState) => ({ ...prevState, open: false }))
            }
            customerData={customerData}
            contactId={contactId || localData?.ContactId}
            getData={getData}
          />
        )}

        {dialog.title === "Add Product Disclaimer" && (
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={actions.addProductAlert}
            message={dialog.message}
          />
        )}
        {dialog.title === "Add Parent Disclaimer" && (
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={actions.addParentAlert}
            message={dialog.message}
          />
        )}
        {dialog.title === "Add SiteSet Disclaimer" && (
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={actions.addSiteSetAlert}
            message={dialog.message}
          />
        )}
      </AppDialog>
      {/* Search customer */}
      <AppDialog
        open={openSelectCustomer}
        maxWidth="lg"
        handleClose={() => setOpenSelectCustomer(false)}
        title="Customer Search"
      >
        <SearchCustomer
          actions={[
            {
              action: searchCustomerSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId || localData?.ContactId}
          propId={propId || localData?.PropId}
        />
      </AppDialog>

      {/* Search site */}
      <AppDialog
        open={openSelectSite}
        maxWidth="lg"
        handleClose={() => setOpenSelectSite(false)}
        title="Site Search"
      >
        <SearchSite
          actions={[
            {
              action: searchSiteSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId || localData?.ContactId}
          propId={propId || localData?.PropId}
        />
      </AppDialog>

      <AppDialog
        open={openSelectUnit}
        maxWidth="xl"
        handleClose={() => setOpenSelectUnit(false)}
        title="Unit Search"
      >
        <SearchUnit
          actions={[
            {
              action: searUnitSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId || localData?.ContactId}
          propId={propId || localData?.PropId}
        />
      </AppDialog>

      <AppDialog
        open={openSelectComponent}
        maxWidth="xl"
        handleClose={() => setOpenSelectComponent(false)}
        title="Component Search"
      >
        <SearchComponent
          actions={[
            {
              action: searComponentsSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId || localData?.ContactId}
          propId={propId || localData?.PropId}
        />
      </AppDialog>
    </>
  );
}
const GetFormButtons = (
  searchContactRowData, localData,
  {
    addProduct,
    addProductAlert,
    addParent,
    addParentAlert,
    addCustomer,
    addSite,
    addUnit,
    addComponent,
    addSiteSet,
    addSiteSetAlert,
    matchAccessAlert,
    exportClick,
  }
) => [
    {
      variant: "outlined",
      label: "Add Product",
      onClick: (searchContactRowData?.IsKomatsu === false || localData?.IsKomatsu === false) ? addProduct : addProductAlert,
      startIcon: <Inventory2Outlined />,
    },
    {
      variant: "outlined",
      label: "Add Parent",
      onClick: (searchContactRowData?.IsKomatsu === false || localData?.IsKomatsu === false) ? addParent : addParentAlert,
      startIcon: <AccessibilityNew />,
    },
    {
      variant: "outlined",
      label: "Add Customer",
      onClick: addCustomer,
      startIcon: <GroupAdd />,
    },
    {
      variant: "outlined",
      label: "Add Site",
      onClick: addSite,
      startIcon: <LanguageOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Unit",
      onClick: addUnit,
      startIcon: <ForumOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Component",
      onClick: addComponent,
      startIcon: <FullscreenExitOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Site Set",
      onClick: (searchContactRowData?.IsKomatsu === false || localData?.IsKomatsu === false) ? addSiteSet : addSiteSetAlert,
      startIcon: <ViewModuleOutlined />,
    },
    {
      variant: "outlined",
      label: "Mach Access",
      onClick: matchAccessAlert,
      hidden: localData?.PropId === 2 || localData?.PropId === 4,
    },
    {
      variant: "outlined",
      label: "Export",
      onClick: exportClick,
    },
  ];
