import { useMemo } from "react";
import NewDataTable, { NewDataGridAction } from "../../components/NewDataTable";

export default function SearchGeneralTable({
    data,
    headerNameList,
    handleSearchSelect,
}) {
    const tableData = useMemo(
        function () {
            return (data || []).map((x, i) => ({ ...x, keyProp: i, }))
        },
        [data]
    );
    const rowActionSelect = [{
        header: "Actions",
        accessorKey: "Actions",
        enableSorting: false,
        maxSize: 70,
        enableEditing: false,
        Cell: function ({ row }) {
            return (
                <NewDataGridAction
                    row={row?.original}
                    onClick={() => handleSearchSelect(row?.original)}
                    label={"Select"}
                />
            );
        },
    },]

    const options = {
        dataSource: tableData,
        columns: rowActionSelect.concat(headerNameList),
        getRowId: (originalRow) => (originalRow?.keyProp || ""),
        enablePagination: false,
        sx: { height: "50dvh" },
        enableColumnActions: false,
        muiTablePaperProps: (a) => {
            return {
                sx: {
                    border: "1px solid #253FC8",
                    borderRadius: "5px",
                    padding: "2px",
                    [`.MuiTableRow-root:hover td`]: { backgroundColor: "#8238ba !important", color: "white" },
                    [`.MuiTableRow-root.Mui-selected td`]: {
                        backgroundColor: "#7030a0!important", color: "white"
                    }
                }
            }
        },
        muiTableBodyRowProps: ({ row, index }) => (
            {
                sx: {
                    backgroundColor: row?.original?.Archived === "Yes" ? "#c4c4c4!important" : (row.index % 2 === 1 ? "#e2f2ff" : ""),
                    color: "white!important",// row?.original?.Archived === "Yes" ? "white!important" : "",
                    cursor: 'pointer',
                },
            }),
        muiTableBodyCellProps: ({ cell, column, row, table }) => {
            return {
                sx: cell.column.columnDef.accessorKey === "Product" ? {
                    backgroundColor: (row?.original?.ProductColour + "!important") || "",
                    color: "white",
                    border: "0.5px solid #d0d0d0",
                    py: 0,
                } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
            }
        }
    };

    return <NewDataTable {...options} />;
}
