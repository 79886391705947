import { useState, useEffect } from "react";
import renderCellExpand from "../../../../components/DatatableCellExpand";
import useFetch from "../../../../hooks/useFetch";
import { CONTACTS_APIS } from "../../../../common/apis";
import AppDialog from "../../../../components/AppDialog";
import ConfirmModal from "../../../../components/ConfirmModal";
import NewDataTable, { NewDataGridAction } from "../../../../components/NewDataTable";

export default function ContactReportsPermissionTable({
  data,
  username,
  getData,
  editReportMappingClicked,
  ...props
}) {
  const { get } = useFetch();

  const [linkageId, setLinkageId] = useState(0)
  const [columnVisibility, setColumnVisibility] = useState({ Customer: false, Site: false, Unit: false, Component: false, SiteSet: false, });

  useEffect(() => {
    if (data) {
      let columnsToDisplay = { ...columnVisibility };
      if (data?.filter(i => i.LevelText === 'Customer')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true };
      }
      if (data?.filter(i => i.LevelText === 'Site')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true };
      }
      if (data?.filter(i => i.LevelText === 'Unit')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true, Unit: true };
      }
      if (data?.filter(i => i.LevelText === 'Component')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true, Unit: true, Component: true };
      }
      if (data?.filter(i => i.LevelText === 'Site Set')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, SiteSet: true };
      }
      setColumnVisibility(columnsToDisplay)
    }
  }, [data])

  async function deleteClick(row) {
    setLinkageId(row?.ContactLinkageId)
  }

  function editClick(row) {
    editReportMappingClicked(row);
  }

  const handleDeleteReport = async () => {
    setLinkageId(0)
    await get(`${CONTACTS_APIS.DELETE_CONTACT_LINKAGE}?ContactLinkageId=${linkageId}&Screen=Contact Reports`);
    getData && getData()
  }

  const options = {
    ...props,
    columns: GetContactReportsPermissionColumns(editClick, deleteClick),
    dataSource: (data || []).map((x) => ({
      ...x, keyProp: x.ContactId + (x.ContactLinkageId || ""),
    })),
    getRowId: (originalRow) => originalRow.ContactScopeId,
    enableColumnActions: false,
    sx: { height: "60dvh", },

    cellStylingHandler: "contactReportsPermission",

    state: { columnVisibility }, //manage columnVisibility state
    onColumnVisibilityChange: setColumnVisibility,

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "EmailStatus" ? {
          backgroundColor: (row?.original?.EmailColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "SampleReportStatus" ? {
          backgroundColor: (row?.original?.SampleReportColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "ExcelStatus" ? {
          backgroundColor: (row?.original?.ExcelColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "MachineReportStatus" ? {
          backgroundColor: (row?.original?.MachineReportColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "SmsStatus" ? {
          backgroundColor: (row?.original?.SmsColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "XmlStatus" ? {
          backgroundColor: (row?.original?.XmlColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "MailStatus" ? {
          backgroundColor: (row?.original?.MailColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
      }
    },

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
  };

  return (
    <>
      <NewDataTable {...options} />
      <AppDialog
        open={linkageId > 0}
        title={"Delete Subscription"}
        maxWidth="md"
        handleClose={() => { setLinkageId(0); }}
      >
        <ConfirmModal
          handleClose={() => { setLinkageId(0); }}
          alertAction={handleDeleteReport}
          message={`Do you wish to Delete the Sample report for ${username || ""}?`}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  )
}

export function GetContactReportsPermissionColumns(editClick, deleteClick) {
  return [
    {
      header: "Edit",
      size: 40,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            minWidth={"40px"}
            row={row?.original}
            onClick={() => editClick(row?.original)}
            label={"Edit"}
          />
        );
      },
    },
    {
      header: "Delete",
      size: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            minWidth={"50px"}
            row={row?.original}
            onClick={() => deleteClick(row?.original)}
            label={"Delete"}
          />
        );
      },
    },
    {
      header: "Email",
      accessorKey: "EmailStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Sample",
      accessorKey: "SampleReportStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Excel",
      accessorKey: "ExcelStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Machine",
      accessorKey: "MachineReportStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "SMS",
      accessorKey: "SmsStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "XML",
      accessorKey: "XmlStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Mail",
      accessorKey: "MailStatus",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Scope",
      accessorKey: "LevelText",
      size: 80,
    },
    {
      header: "Product",
      accessorKey: "Product",
      size: 100,
    },
    {
      header: "Site Set",
      accessorKey: "SiteSet",
      size: 100,
      renderCell: renderCellExpand,
    },
    {
      header: "Parent",
      accessorKey: "Parent",
      size: 100,
      renderCell: renderCellExpand,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      size: 155,
      renderCell: renderCellExpand,
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 150,
      renderCell: renderCellExpand,
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 150,
      renderCell: renderCellExpand,
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 100,
      renderCell: renderCellExpand,
    },
  ];
}
