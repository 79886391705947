import React, { useEffect, useState, Suspense } from "react";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppRouter from "./AppRouter";
import { useRoot } from "./rootContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal, } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Close, Error } from "@mui/icons-material";
import useFetch from "./hooks/useFetch";
import AppTitleBar from "./AppTitleBar";
import UserNotFound from "./components/UserNotFound";
import useRestrictedResolution from "./components/useRestrictedResolution";
import appResources from "./app-resources/en/Resources.json";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking, } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { msai } from "./authConfig";
import { WindowRoutes } from "./components/useWindowPopups";
import moment from "moment";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

function App() {

  const { screenBlocked } = useRoot();
  let isResolutionRestricted = useRestrictedResolution(1366, 651);
  const [checkLocation, setCheckLocation] = useState("");

  useEffect(() => {
    let check = (WindowRoutes.includes(window.location?.pathname))
    setCheckLocation(check);
  }, []);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={MsalErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <ErrorComponent />
          <AuthenticatedTemplate>
            <RouterWrapper />
          </AuthenticatedTemplate>
          <ToastMessage />
          <Backdrop
            sx={isResolutionRestricted === true
              ? { backgroundColor: "#fff" }
              : { backgroundColor: "rgba(246, 246, 246,0.5)" }}
            open={screenBlocked || (isResolutionRestricted && checkLocation === false)}
            style={{ zIndex: 2000 }}
          >
            {
              (isResolutionRestricted === true && checkLocation === false)
                ? <Typography sx={{ color: "red", fontSize: 13 }}>
                  {appResources.SCREEN_NOT_SUPPORTED}
                </Typography>
                :
                <CircularProgress color="inherit" />
            }
          </Backdrop>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </MsalAuthenticationTemplate>
  );
}

function RouterWrapper() {
  // const { t } = useTranslation("common");
  const { get } = useFetch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const { setUser, user, } = useRoot();

  useEffect(function () {
    const up = JSON.parse(localStorage.getItem("USER-PROFILE"));
    let checkDate = moment.utc().format("YYYYMMDD")

    if (checkDate === up?.CreationDate) {

      setUser(up);

      const emailId = up?.EmailAddress;
      appInsights.trackException({ name: emailId });
      appInsights.trackEvent({ name: emailId });
      appInsights.trackPageView({ properties: { name: emailId } });
    }

    else {
      (async function () {
        const temp = await get("/account/profile");
        localStorage.setItem("USER-PROFILE", JSON.stringify({
          ...temp,
          RealDefaultLaboratory: temp?.DefaultLaboratory,
          RealDefaultLaboratoryId: temp?.DefaultLaboratoryId,
          CreationDate: moment.utc().format("YYYYMMDD")
        }));
        const emailId = temp?.EmailAddress;

        setUser(
          {
            ...temp,
            RealDefaultLaboratory: temp?.DefaultLaboratory,
            RealDefaultLaboratoryId: temp?.DefaultLaboratoryId,
          });

        appInsights.trackException({ name: emailId });
        appInsights.trackEvent({ name: emailId });
        appInsights.trackPageView({ properties: { name: emailId } });

      })();
    }
  }, []);

  let location = useLocation();
  const [checkLocation, setCheckLocation] = useState("");

  useEffect(() => {
    let check = (WindowRoutes.includes(location?.pathname))
    setCheckLocation(check);

  }, [location]);

  return (
    <>
      {user.UserId ? (
        (
          <>
            {!checkLocation && <AppTitleBar />}

            <Box sx={{ marginTop: checkLocation === true ? "" : "40px", p: 1.75 }}>
              <Suspense fallback={<div>...</div>}>
                <AppRouter user={user} />
              </Suspense>
            </Box>
          </>
        )
      ) : (
        <Routes>
          <Route
            path="/user-not-found"
            element={<UserNotFound account={account} />}
          />
        </Routes>
      )}
    </>
  );
}

function ToastMessage() {

  const { toastOptions, setToastOption } = useRoot();
  const handleClose = (event, reason) => {
    // setToastOption({});
    setToastOption((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      <Snackbar
        // open={!!toastOptions.text}
        open={toastOptions.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
        >
          {toastOptions.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

function ErrorComponent() {
  const theme = useTheme();
  const { errorText, setErrorText } = useRoot();

  return (
    errorText && (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 1,
          bgcolor: theme.palette.error.dark,
          color: theme.palette.common.white,
          position: "fixed",
          zIndex: 2001,
        }}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Error />
            <Typography variant="subtitle2" sx={{ ml: theme.spacing(0.5) }}>
              {errorText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setErrorText("")} color="inherit">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    )
  );
}

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: msai?.instrumentationKey, //process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };

export default withAITracking(reactPlugin, App);

function MsalErrorComponent({ error }) {
  return <>An Error Occurred: {error}</>;
}

function LoadingComponent() {
  return <>Authentication is in progress...</>;
}
