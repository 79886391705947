import { Grid, Typography, } from "@mui/material";

import SampleReportsOverviewFormTable from "./PrimaryContactOverviewFormTable";
import useFetch from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";

export default function PrimaryContactOverview({
  formData,
  rowDataProps,
  setOpenDialog,
}) {
  const { post } = useFetch();

  const [primaryContactData, setPrimaryContactData] = useState([]);

  function rowSelectedHandler(rowData) {
    rowDataProps && rowDataProps(rowData);
  }

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("primary-contacts-overview")
      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
        document.title = "Primary Contacts Overview - " + (parseData?.Customer || "");
      }

      let resData = await post(FLEET_APIS.CONTACTS_FOR_PRIMARY, {
        SampleNumber: formData?.SampleNumber || parseData?.SampleNumber,
        CustomerId: formData?.CustomerId || parseData?.CustomerId,
        SiteId: formData?.SiteId || parseData?.SiteId,
        UnitId: formData?.UnitId || parseData?.UnitId,
        ReportNumber: formData?.ReportNumber || parseData?.ReportNumber,
        ContactId: formData?.ContactId || parseData?.ContactId,
        ProductId: formData?.ProductId || parseData?.ProductId,
        SiteSetId: formData?.SiteSetId || parseData?.SiteSetId,
        ParentId: formData?.ParentId || parseData?.ParentId,
        ComponentId: formData?.ComponentId || parseData?.ComponentId,
      });
      setPrimaryContactData(resData);
      if ((parseData?.Customer?.length < 1 || parseData?.Customer === undefined) && resData?.length > 0) {
        document.title = "Primary Contacts Overview - " + (resData[0]?.Company || "");
      }
      localStorage.removeItem("primary-contacts-overview");

    })();
  }, [formData?.SampleNumber]);

  return (
    <>
      <Grid container spacing={2} sx={{ minWidth: "90vw" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:{" "}
            <b> {primaryContactData && primaryContactData.length}</b>
          </Typography>
          <SampleReportsOverviewFormTable
            setOpenDialog={setOpenDialog}
            data={primaryContactData}
            rowSelected={rowSelectedHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}
