import { Card, Grid, Typography, } from "@mui/material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { CRITICAL_LOGS_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";

import { EventReducer, FormSections, SearchFilterDefaults, } from "./AccessPermissionOverviewForm";
import AccessPermissionOverviewTable from "./AccessPermissionOverviewTable";
import Form from "./../../../../components/Form";
import { useWindowPopups } from "../../../../components/useWindowPopups";

export default function AccessPermissionOverview({
  formData,
  rowDataProps,
  setOpenDialog,
  setDialog,
}) {
  const { post, postFile } = useFetch();
  const [data, dispatch] = useReducer(EventReducer, SearchFilterDefaults);
  const [accessPermissionOverviewData, setAccessPermissionOverviewData] = useState([]);
  const [checkLocation, setCheckLocation] = useState(false);
  const [localData, setLocalData] = useState({});

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, { selectCheck: true, ...data });
  }

  function searchContactScope() {
    HandleNewWindow("search-contact",)
  }

  function rowSelectedHandler(rowData) {
    rowDataProps && rowDataProps(rowData);
  }

  const handleExportContacts = async () => {
    postFile(CRITICAL_LOGS_APIS.EXPORT_CONTACTS_FOR_SCOPE, {
      SampleNumber: formData?.SampleNumber || localData?.SampleNumber,
      ParentId: formData?.ParentId || localData?.ParentId,
      ProductId: formData?.ProductId || localData?.ProductId,
      CustomerId: formData?.CustomerId || localData?.CustomerId,
      ContactId: formData?.ContactId || localData?.ContactId,
      SiteSetId: formData?.SiteSetId || localData?.SiteSetId,
      SiteId: formData?.SiteId || localData?.SiteId,
      UnitId: formData?.UnitId || localData?.UnitId,
      ComponentId: formData?.ComponentId || localData?.ComponentId,
      ReportNumber: formData?.ReportNumber || localData?.ReportNumber,
    },
      "Access Contacts"
    );
  };

  const filteredData = useMemo(
    () =>
      data
        ? (accessPermissionOverviewData || []).filter((x) => {

          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.DisplayName?.toLowerCase().includes(filterLower)
              || x.Username?.toLowerCase().includes(filterLower)
              || x.Company?.toLowerCase().includes(filterLower);
          }

          return (
            filterMatch && ((data.Komatsu && x.IsKomatsu) ||
              (data.Other && !x.IsKomatsu)) &&
            ((data.Product && x.Scope === "Product") ||
              (data.SiteSet && x.Scope === "Site Set") ||
              (data.Customer && x.Scope === "Customer") ||
              (data.Site && x.Scope === "Site") ||
              (data.Unit && x.Scope === "Unit") ||
              (data.Parent && x.Scope === "Parent") ||
              (data.Unit && x.Scope === "Unit") ||
              (data.Component && x.Scope === "Component"))
          );
        })
        : accessPermissionOverviewData,
    [data, accessPermissionOverviewData]
  );

  function selectClick(row) {
    setOpenDialog && setOpenDialog(false)
    rowSelectedHandler && rowSelectedHandler(row);
    setDialog && setDialog((prevState) => ({ open: false, }));
    if (window.location.pathname.includes("access")) {
      localStorage.setItem("apo-resp-" + localData?.SampleNumber, JSON.stringify(row));
      window.dispatchEvent(new Event('storage'))
      return window.close();
    }
  }

  useEffect(() => {
    (async function () {
      let parseData = JSON.parse(localStorage.getItem("access-permission-overview"));

      setCheckLocation((parseData?.selectCheck === true || formData?.selectCheck === true) ? true : false);
      setLocalData(parseData)

      let resData = await post(CRITICAL_LOGS_APIS.CONTACTS_FOR_SCOPE, {
        SampleNumber: formData?.SampleNumber || parseData?.SampleNumber,
        ParentId: formData?.ParentId || parseData?.ParentId,
        ProductId: formData?.ProductId || parseData?.ProductId,
        CustomerId: formData?.CustomerId || parseData?.CustomerId,
        ContactId: formData?.ContactId || parseData?.ContactId,
        SiteSetId: formData?.SiteSetId || parseData?.SiteSetId,
        SiteId: formData?.SiteId || parseData?.SiteId,
        UnitId: formData?.UnitId || parseData?.UnitId,
        ComponentId: formData?.ComponentId || parseData?.ComponentId,
        ReportNumber: formData?.ReportNumber || parseData?.ReportNumber,
      });
      setAccessPermissionOverviewData(resData);
      if (formData?.selectCheck !== true) {
        document.title = "Access Permission Overview - " + (parseData?.Customer || "");
        if ((parseData?.Customer?.length < 1 || parseData?.Customer === undefined) && resData?.length > 0) {
          document.title = "Access Permission Overview - " + (resData[0]?.Customer || "");
        }
      }
    })();
  }, [formData?.SampleNumber]);

  return (
    <>
      <Grid container spacing={2} minWidth={"90vw"}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={FormSections(searchContactScope, handleExportContacts)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <AccessPermissionOverviewTable
            data={filteredData}
            rowSelected={rowSelectedHandler}
            selectClick={selectClick}
            selectCheck={checkLocation}
          />
        </Grid>
      </Grid>
    </>
  );
}
