import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "../../components/Form";
import DataTable, { DataGridAction } from "../../components/DataTable";
import { DateFormat, toLocalDate2 } from "../../utils";
import useFetch from "../../hooks/useFetch";
import { MASTER_DATA_APIS, TRAY_APIS } from "../../common/apis";
import Hold from "../trays/laboratory/Hold";
import AppDialog from "../../components/AppDialog";
import { ExcelIcon, } from "../../icons/ButtonIcons";
import { useToast, useUser } from "../../rootContext";
import moment from "moment/moment";
import { LabSteps } from "../../common/Constants";
import appResources from "../../app-resources/en/Resources.json";
import ConfirmModal from "../../components/ConfirmModal";

export default function HoldManagement({ unitId, SampleNumber }) {
  const user = useUser();
  const { post, get, getFile } = useFetch();

  const SearchFilterDefaults = {
    HoldTypeId: -1,
    LaboratoryId: user?.DefaultLaboratoryId
  };

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const [labDropdownData, setLabDropdownData] = useState([]);
  const [holdTypesDropdownData, setHoldTypesDropdownData] = useState([]);
  const [samplesOnHoldDataArray, setSamplesOnHoldDataArray] = useState([]);
  const [samplesOnHoldData, setSamplesOnHoldData] = useState([]);
  const [holdOpen, setHoldOpen] = useState(false);
  const { showErrorToast } = useToast();
  const [filterState, setFilterState] = useState();
  const [selectedSampleNo, setSelectedSampleNo] = useState(0);
  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    actionName: "",
    open: false,
    width: "",
  });

  //Export excel

  async function loadData(ls) {
    let responseLabData = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Laboratories,HoldTypes`, { skipBlocker: true });
    if (responseLabData) {
      responseLabData.Laboratories.unshift({
        LaboratoryId: 0,
        Laboratory: "All Laboratories",
      });
      responseLabData.HoldTypes.unshift({
        HoldTypeId: -1,
        HoldType: "All Hold Samples",
      });

      setLabDropdownData(responseLabData.Laboratories);
      setHoldTypesDropdownData(responseLabData.HoldTypes);
    }
    let responseData = await get(`${TRAY_APIS.SAMPLE_ON_HOLD}?labId=${ls?.LaboratoryId || data?.LaboratoryId}&holdType=${ls?.HoldTypeId || data?.HoldTypeId}`);
    if (responseData) {
      setSamplesOnHoldDataArray(responseData);
    }
  }

  function excelExportClick() {
    getFile(`${TRAY_APIS.EXPORTS_SAMPLES_ON_HOLD}?labId=${data.LaboratoryId}&holdType=${data.HoldTypeId}`, "Hold Management");
  }

  async function onViewHandler(filter) {
    if (filter.Date === "Invalid date") {
      return showErrorToast(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setFilterState(filter);
    const resp = await post(TRAY_APIS.GET_LABORATORY, {
      Date:
        filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Registration,
      MaximumStep:
        filter.LabAndDateOption === 0
          ? LabSteps.Completed
          : LabSteps.Laboratory,
      Hold: true,
    });

    if (resp.Tray.length > 0) {
      setSelectedSampleNo(resp.Tray[0].SampleNumber);
    } else {
      setSelectedSampleNo(0);
    }
  }

  const getSamples = async () => {
    let responseData = await get(`${TRAY_APIS.SAMPLE_ON_HOLD}?labId=${data?.LaboratoryId}&holdType=${data?.HoldTypeId || data?.HoldTypeId}`);
    if (responseData) {
      setSamplesOnHoldDataArray(responseData);
    }
  }

  function refreshGrid() {
    if (filterState) {
      onViewHandler(filterState);
    }
    getSamples()
  }
  function viewCLick() {
    loadData();
  }

  async function returnClickAlert(rowData) {
    const response = await get(`${TRAY_APIS.RETURN_SAMPLE_ON_HOLD}?SampleNumber=${rowData.SampleNumber}&Step=${rowData.Step}&Screen=Hold Management`);
    if (response > 0) {
      localStorage.setItem("return-to-lab-resp", JSON.stringify({ resp: true, SampleNumber: rowData?.SampleNumber }));
      loadData();
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
  }
  //For return Sample
  function returnClick(row) {
    setDialog({
      title: "Return Sample From On Hold",
      message: `Do you wish to RETURN sample ${row.SampleNumber} to step  ${row.Step}?`,
      actionName: (() => returnClickAlert(row)),
      open: true,
      width: "md",
    });
  }
  //For return Tray

  async function returnAllClickAlert(rowData) {
    const response = await get(
      `${TRAY_APIS.RETURN_TRAY_ON_HOLD}?SampleNumber=${rowData.SampleNumber}&Step=${rowData.Step}&Screen=Hold Management`
    );
    if (response > 0) {
      loadData();
      localStorage.setItem("return-to-lab-resp", JSON.stringify({ resp: true, SampleNumber: rowData?.SampleNumber }));
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));

  }

  function returnAllClick(row) {
    setDialog({
      actionName: (() => returnAllClickAlert(row)),
      title: `Return Tray ${row.Tray} From On Hold`,
      message: `Do you wish to RETURN all the samples in tray ${row.Tray} for ${row.Laboratory} that are on hold?`,
      open: true,
      width: "md",
    });
  }

  function editClick(row) {
    setSelectedSampleNo(row?.SampleNumber);
    setHoldOpen(true);
  }

  const tblOptions = {
    columns: GetColumns(data, editClick, returnClick, returnAllClick),
    dataSource: (samplesOnHoldData || []).map((x) => ({
      ...x,
      keyProp:
        (x.SampleNumber || "") + (x.TrayDate || "") + (x.Identifier || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: false,
    sx: { height: 680 },
  };

  useEffect(() => {
    (async function () {
      document.title = "Hold Management";
      let parseData = {};
      let formDatas = localStorage.getItem("hold-management")
      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
        return loadData(parseData);
      }
      loadData();
    })();
  }, [data.LaboratoryId, data.HoldTypeId]);

  useEffect(() => {
    data.ByTray
      ? setSamplesOnHoldData(samplesOnHoldDataArray.Summary)
      : setSamplesOnHoldData(samplesOnHoldDataArray.Samples);
  }, [samplesOnHoldDataArray, data.ByTray]);

  return (
    <>
      <Grid container spacing={2} sx={{ minWidth: "75vw" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                labDropdownData,
                holdTypesDropdownData,
                viewCLick,
                excelExportClick,
                user,
                samplesOnHoldData
              )}
              data={data}
              spacing={2}
              dispatch={dispatch}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:
            <b> {samplesOnHoldData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={holdOpen}
        handleClose={() => setHoldOpen(false)}
        title="Put on Hold"
        maxWidth="xl"
      >
        <Hold
          sampleId={selectedSampleNo}
          refreshGrid={refreshGrid}
          setHoldOpen={setHoldOpen}
        />
      </AppDialog>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        <ConfirmModal
          handleClose={() => {
            setDialog((prevState) => ({
              ...prevState,
              open: false,
            }));
          }}
          alertAction={dialog.actionName}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(data, editClick, returnClick, returnAllClick) {
  return [
    {
      headerName: "Edit",
      field: "edit",
      hide: data.ByTray,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Sample",
      field: "sample",
      hide: data.ByTray,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={returnClick} label="Return" />
          </>
        );
      },
    },
    {
      headerName: "Tray",
      field: "tray",
      hide: data.ByTray,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={returnAllClick}
              label="Return All"
            />
          </>
        );
      },
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      width: 110,
    },
    {
      headerName: "Tray",
      field: "Tray",
      width: 50,
    },
    {
      headerName: "Tray Date",
      field: "TrayDate",
      valueFormatter: ({ value }) => toLocalDate2(value),
      width: 110,
    },
    {
      headerName: "SampleNumber",
      hide: data.ByTray,
      field: "SampleNumber",
      width: 120,
    },
    {
      headerName: "Step",
      field: "Step",
      hide: data.ByTray,
      width: 60,
    },
    {
      headerName: "HoldType",
      field: "HoldType",
      hide: data.ByTray,
      width: 170,
    },
    {
      headerName: "Added",
      field: "HoldAdded",
      hide: data.ByTray,
      valueFormatter: ({ value }) => toLocalDate2(value),
      width: 130,
    },
    {
      headerName: "Hold Until",
      field: "HoldUntil",
      hide: data.ByTray,
      valueFormatter: ({ value }) => toLocalDate2(value),
      width: 130,
    },
    {
      headerName: "Added By",
      hide: data.ByTray,
      field: "HoldAddedBy",
      width: 110,
    },
    {
      headerName: "Minimum Step",
      field: "MinimumStep",
      hide: !data.ByTray,
      width: 150,
    },
    {
      headerName: "Maximum Step",
      field: "MaximumStep",
      hide: !data.ByTray,
      width: 170,
    },
    {
      headerName: "Total",
      field: "SampleCount",
      hide: !data.ByTray,
      width: 170,
    },
    {
      headerName: "Reason",
      field: "HoldNotes",
      hide: data.ByTray,
      width: 500,
      flex: 1,
    },
  ];
}

function GetFormSections(
  labDropdownData,
  holdTypesDropdownData,
  viewCLick,
  excelExportClick,
  user,
  samplesOnHoldData
) {
  return [
    {
      fluid: true,
      fields: [
        {
          xsCol: 2,
          name: "LaboratoryId",
          label: "Laboratory",
          type: "dropdown",
          selectOptions: (labDropdownData || [])
            .filter((x) => x.Laboratory !== "External")
            .map((x) => ({
              value: x.LaboratoryId,
              text: x.Laboratory,
            })),
        },
        {
          xsCol: 2,
          name: "HoldTypeId",
          label: "Type",
          type: "dropdown",
          required: true,
          selectOptions: (holdTypesDropdownData || []).map((x) => ({
            value: x.HoldTypeId,
            text: x.HoldType,
          })),
        },
        {
          name: "ByTray",
          label: "By Tray",
          type: "switch",
        },
        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={viewCLick}
                sx={{ mt: "15px" }}
              >
                View
              </Button>
            );
          },
        },
        {
          component: function () {
            return (
              samplesOnHoldData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={excelExportClick}
                  sx={{ mt: "15px" }}
                  startIcon={<ExcelIcon />}
                >
                  Excel
                </Button>
              )
            );
          },
        },
        // {
        //   name: "ShowSample",
        //   label: `Show Sample ${SampleNumber}`,
        //   type: "switch",
        // },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
