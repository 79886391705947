import { FormControlLabel, Radio, } from "@mui/material";

export default function RadioOption({ id, name, label, value, ...options }) {
  return (
    <FormControlLabel
      sx={{
        width: "auto",
        height: 16,
      }}
      control={
        <Radio
          value={value}
          name={name}
          id={id}
          {...options}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 16,

            },
          }} />
      }
      label={label}
    />
  );
}
