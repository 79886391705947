import {
  Autocomplete,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";

export default function DropDownAdvance({
  id,
  name,
  label,
  renderOptions,
  filterOptions,
  options,
  getOptionLabel,
  onChange,
  errorMsg,
  defaultValue,
  readOnly,
}) {

  // Above function filters the list from 'start'
  return (
    <>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Autocomplete
        sx={{
          "& .MuiFilledInput-root": {
            p: "5px 9px",
            "& .MuiAutocomplete-input": {
              p: 0,
            },
            "& .MuiAutocomplete-popupIndicator": {
              mt: 0.9,
            },
          },
        }}
        componentsProps={{ popper: { style: { width: "fit-content" } } }}
        // componentsProps={{
        //   paper: {
        //     sx: {
        //       width: 700,
        //     },
        //   },
        // }}

        disableClearable
        filterOptions={filterOptions}
        // filterOptions={filterOptions1}
        options={options}
        onChange={onChange}
        autoHighlight
        getOptionLabel={getOptionLabel}
        renderOption={renderOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        readOnly={readOnly}
        value={defaultValue}
      />
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
      )}
    </>
  );
}
