import { useState } from "react";
import { CONTACTS_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import AppDialog from "../../../../components/AppDialog";
import { useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";
import ConfirmModal from "../../../../components/ConfirmModal";
import NewDataTable, { NewDataGridAction } from "../../../../components/NewDataTable";

export default function ContactAccessPermissionTable({
  getData,
  data,
  rowSelected,
  ...props
}) {

  const [rowData, setRowData] = useState();
  const { get } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });

  const options = {
    ...props,
    columns: GetContactAccessPermissionColumns(deleteClick),
    dataSource: data,
    getRowId: (originalRow) => originalRow.ContactScopeId,
    enableColumnActions: false,
    sx: { height: "60dvh", },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Product" ? {
          backgroundColor: (row?.original?.ProductColour + "!important") || "",
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
        } : { height: "8px", borderLeft: "0.5px solid #d0d0d0", }
      }
    },

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
  };

  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }

  async function deleteClickAlert() {
    const response = await get(`${CONTACTS_APIS.DELETE_SCOPE_FOR_CONTACT}?ContactScopeId=${rowData.ContactScopeId}&Screen=Contact Access Permissions`);
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
      getData();
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  return (
    <>
      <NewDataTable {...options} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Access"
        maxWidth="md"
      >
        <ConfirmModal
          handleClose={() => { setDialog((prevState) => ({ ...prevState, open: false })); }}
          alertAction={deleteClickAlert}
          message={dialog.message}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

export function GetContactAccessPermissionColumns(deleteClick) {
  return [
    {
      header: "Delete",
      size: 50,
      Cell: function ({ row }) {
        return (
          <NewDataGridAction
            row={row?.original}
            onClick={() => deleteClick(row?.original)}
            label={"Delete"}
          />
        );
      },
    },
    {
      header: "Scope",
      accessorKey: "Scope",
      size: 150,
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 150,
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 150,
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 150,
    },

    {
      header: "Customer",
      accessorKey: "Customer",
      size: 150,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      header: "Parent",
      accessorKey: "Parent",
      size: 150,
      renderCell: renderCellExpand,
    },
    {
      header: "Site Set",
      accessorKey: "SiteSet",
      size: 150,
      renderCell: renderCellExpand,
    },

    {
      header: "Product",
      accessorKey: "Product",
      size: 150,
      renderCell: renderCellExpand,
    },
  ];
}
