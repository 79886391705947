import { Box, FormHelperText, InputLabel, TextField, } from "@mui/material";
import { forwardRef } from "react";

const TextBox = forwardRef(function (
  { placeHolder,
    autoComplete,
    id,
    name,
    label,
    label2,
    label2Color,
    label2bgColor,
    defaultValue,
    onChange,
    onChangeAsync,
    errorMsg,
    readOnly,
    sx,
    handleEnterPress,
    tabIndex,
    ...options
  },
  ref
) {
  return (
    <>
      <InputLabel htmlFor={id}>
        <Box sx={{ display: "inline", color: options?.labelColor || "" }}> {label}</Box>
        <Box sx={{ display: "inline", px: 0.5, float: "right", color: (label2Color || "#a54aa5"), backgroundColor: (label2bgColor || "") }}> {label2 || ""} </Box>
      </InputLabel>

      <TextField
        autoComplete={autoComplete === true ? "on" : "off"}
        sx={sx}
        inputProps={{ readOnly: readOnly, tabIndex: tabIndex || "0" }}
        placeholder={placeHolder}
        inputRef={ref}
        id={id}
        name={name}
        defaultValue={defaultValue}
        onChange={
          onChange ||
          ((e) => {
            onChangeAsync &&
              setTimeout(function () {
                onChangeAsync(e);
              }, 0);
          })
        }
        error={!!errorMsg}
        onKeyUp={handleEnterPress}
        {...options}
      />
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg || ""}</FormHelperText>
      )}
    </>
  );
});
export default TextBox;
