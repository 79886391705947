import { colors, createTheme } from "@mui/material";

var fontsize = 11; //input fields, table data,
var labelsize = 11; //screen labels
var inputfieldlabel = 11; //input fields labels
var svgiconsize = 12; //all icons
var fontfamily = "Inter";

export const customTheme = createTheme({
  palette: {
    primary: {
      light: "#425FF6", //"Main ui color"
      main: "#253fc8",
      dark: "#253FC8",
      // dark: "#0086b4",
      contrastText: colors.common.white,
    },
    //Light sea green is for buttons to identify if they contain some values
    lightseagreen: {
      light: "#20b2aa", //"Main ui color"
      main: "#20b2aa",
      dark: "#20b2aa",
      contrastText: colors.common.white,
    },
    danger: {
      light: "#FF0000", //"Main ui color"
      main: "#FF0000",
      dark: "#FF0000",
      contrastText: colors.common.white,
    },
    purple: {
      light: "#800080", //"Main ui color"
      main: "#800080",
      dark: "#800080",
      contrastText: colors.common.white,
    },
  },
  typography: {
    fontSize: fontsize,
    fontFamily: fontfamily,
    fontWeightLight: 500,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    button: {
      textTransform: "none",
      fontSize: `${fontsize}px!important`,
    },
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application
      },
      // styleOverrides: {
      //   root: ({ theme }) => ({
      //     fontSize: theme.typography.fontSize,
      //   }),
      // },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
          [theme.breakpoints.keys[4]]: {
            fontSize: "42px!important",
          },
        }),
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
          [`& .MuiTreeItem-content .MuiTreeItem-label`]: {
            fontSize: theme.typography.fontSize,
          },
        }),
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: theme.spacing(4),
          maxHeight: theme.spacing(4),
          backgroundColor: theme.palette.primary.main,
          color: "white",
          "&.Mui-expanded": {
            maxHeight: theme.spacing(4),
            minHeight: theme.spacing(4),
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: fontsize, fontWeight: "600" },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          color: "black",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRight: "none",
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({ fontSize: svgiconsize }),
      },
    },
    MuiButton: {
      defaultProps: { variant: "contained" },
      styleOverrides: {
        root: () => ({
          fontSize: "0.7rem",
          lineHeight: 0,
          height: "26px",
          "&:hover": {
            backgroundColor: "#3374F0", //"#558EFF",
            color: "#ffffff",
            borderColor: "#558EFF",
          },
          [`& .MuiButton-startIcon>*:nth-of-type(1) `]: {
            fontSize: svgiconsize,
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: () => ({ padding: "6px" }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: () => ({
          width: "100%",
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#f9f9f9",
          width: "100%",
          fontSize: fontsize,
        }),
        multiline: ({ ownerState }) => ({ padding: "0" }),
        input: ({ ownerState }) => ({
          padding: "5px 9px",
          height: "auto",
          fontSize: fontsize,
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // inputRoot: ({ ownerState }) => ({ padding: "5px 9px" }),
        //input: ({ ownerState }) => ({ padding: "0" }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: () => {
          // return { fontSize: "0.8rem" }; //padding: "0 9px",
          return { fontSize: fontsize }; //padding: "0 9px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: () => {
          return { border: "1px solid rgba(0, 0, 0, 0.23);" };
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: () => ({
          fontSize: fontsize,
          padding: "none",
          [`.MuiDataGrid-cell`]: { padding: "0px 2px" },
          [` .MuiDataGrid-columnHeader`]: { padding: "0px 3px", borderLeft: "1px solid #d0d0d0" },
          [`.MuiDataGrid-columnSeparator--sideRight`]: { display: "none" },
        }),
        columnHeaderTitle: () => ({ fontWeight: "bold", textAlign: "center" }),
      },
    },
    // MuiGrid: {
    //   styleOverrides: {
    //     root: () => ({ [`&.MuiGrid-item`]: { paddingLeft: "7px" } }),
    //   },
    // },
    MuiInputLabel: {
      defaultProps: {
        shrink: false,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: inputfieldlabel,
          color: "#000000",
          lineHeight: "1.2em",
        }),
      },
    },
    MuiFormLabel: {
      // defaultProps: {
      //   shrink: false,
      // },
      styleOverrides: {
        root: ({ ownerState }) => ({ fontSize: labelsize, color: "#000000" }),
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: () => ({ fontSize: labelsize }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({ minHeight: theme.spacing(5) }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => {
          return { minHeight: theme.spacing(5) };
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontSize: "0.92rem",
            padding: "5px 15px",
          };
        },
      },
    },
  },
});
